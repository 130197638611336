import React from 'react';
import Helmet from 'react-helmet';
import { Grid, Row, Col } from 'react-flexbox-grid';
import PageHeader from 'components/PageHeader';
import styles from './index.module.less';
import { List, ListItem, Paragraph } from 'components/Typography';
import Layout from 'components/Layout';

const Page = () => (
  <Layout>
    <Helmet
      title="Apply"
      meta={[
        {
          name: 'description',
          content: 'Apply for help to repair your home.'
        }
      ]}>
      <html lang="en" />
    </Helmet>
    <PageHeader title="Apply" />
    <Grid fluid className={styles.main}>
      <Row>
        <Col lg={8} className={styles.conditions}>
          <Paragraph>When responding to your enquiry, we will provide information on our current service model, including the level of financing which can be approved for a home repairs project. For those who qualify for services we organise regular assessments rounds for our yearly home repairs calendar.</Paragraph>
          <br />
          <Paragraph>Conditions that will apply:</Paragraph>
          <List>
            <ListItem>You will need to disclose your financial situation to be applicable for our 0% interest loans for home repairs.</ListItem>
            <ListItem>The involvement of family members could be required, including time and labour where appropriate such as clearing areas for repair work, and painting which is a service we do not provide.</ListItem>
          </List>
        </Col>
      </Row>
      <Row>
        <Col lg={8}>
          <form
            className={styles.form}
            name="application"
            method="POST"
            action="/apply/confirmation"
            data-netlify="true"
            data-netlify-honeypot="honeypot">
            <input type="hidden" name="honeypot" />
            <input type="hidden" name="form-name" value="application" />
            <p className={styles.field}>
              <label className={styles.label} htmlFor="fname">
                First name
              </label>
              <input className={styles.input} name="fname" required />
            </p>
            <p className={styles.field}>
              <label className={styles.label} htmlFor="lname">
                Last name
              </label>
              <input className={styles.input} name="lname" required />
            </p>
            <p className={styles.field}>
              <label className={styles.label} htmlFor="phone">
                Phone
              </label>
              <input className={styles.input} name="phone" type="tel" />
            </p>
            <p className={styles.field}>
              <label className={styles.label} htmlFor="email">
                Email address
              </label>
              <input className={styles.input} name="email" required />
            </p>
            <p className={styles.field}>
              <label className={styles.label} htmlFor="comment">
                Tell us about your housing problems and what you would like Ryhaven Trust to do.
                Please list the work in order of priority. We may not be able to do all of the work
                you ask for.
              </label>
              <textarea className={styles.textarea} name="comment"></textarea>
            </p>
            <button className={styles.button} type="submit">
              Send application
            </button>
          </form>
        </Col>
      </Row>
    </Grid>
  </Layout>
);

export default Page;
